import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import axiosInstance from 'utils/axios.config';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #fff',
  boxShadow: 24,
  p: 4,
};


export default function ModalRestaurant({open, handleClose,rboksl}) {

const [modalViewBook,setModalViewBook] = useState([])

  console.log( 'modalViewBook', modalViewBook)

  const {rstbranch_name,bkqrimg ,arrivaltime ,rstbr_address, rstbr_email, bkdates , bkstatus ,bokfor , rstbr_typ , numguests , rstbr_description , rstcustomersrvs} = modalViewBook;
  React.useEffect(() => {
    axiosInstance
      .get(`https://api.hellokompass.com/restubookdetails/${rboksl}`)
      .then((res) => {
        setModalViewBook(res.data.data);
      })
      .catch((error) => console.error(error));
  }, [rboksl]);
  return (
    <div>
   
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>

<Box>
  <img src={bkqrimg} alt="" />
</Box>



       <Box sx={{display:'flex' , justifyContent:'space-between'}}>
       <Typography sx={{ fontWeight: 'bolder', fontFamily: 'Poppins', fontSize: '16px', color: '#0C1E21' }}>
          {rstbranch_name}
        </Typography>
     <Box sx={{background:'#12a9b2', px:'5px',display:'flex',alignItems:'center',borderRadius:'2px' }}>
     <Typography sx={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '12px', color: '#fff' }}>
          {bkstatus}
        </Typography>
     </Box>
       </Box>
      
        <Typography  sx={{ fontWeight: 'medium', fontFamily: 'Poppins', fontSize: '12px', color: '#333' ,mt:'4px' }}>
          Arrival Time : {arrivaltime}
        </Typography>
        <Typography  sx={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '12px', color: '#333',mt:'4px'  }}>
          Date: {bkdates}
        </Typography>
  
     
        <Typography  sx={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '12px', color: '#333',mt:'4px'  }}>
          Dinding : {bokfor}
        </Typography>
        <Typography  sx={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '12px', color: '#333',mt:'4px'  }}>
          Guest Number : {numguests}
        </Typography>
        <Typography  sx={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '12px', color: '#333' ,mt:'4px' }}>
         Location:  {rstbr_address}
        </Typography>
        <Typography  sx={{ fontWeight: 'medium', fontFamily: 'Poppins', fontSize: '12px', color: '#333',mt:1 }}>
         Description : {rstbr_description}
        </Typography>
        <Typography  sx={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '12px', color: '#333',mt:'4px'  }}>
          Email : {rstbr_email}
        </Typography>
        <Typography  sx={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '12px', color: '#333',mt:'4px'  }}>
          Cuisine : {rstbr_typ}
        </Typography>
       
     
      </Box>
    </Modal>
  </div>
  )
}
