import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Box } from '@mui/material';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'react-image-lightbox/style.css'; 
import Lightbox from 'react-image-lightbox';
import 'swiper/css';

import './style.css';
export default function SwiperSlider({ restuinfo }) {
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
  
    const images = restuinfo?.rstbr_images || [];
  
    const openLightbox = (index) => {
      setPhotoIndex(index);
      setIsOpen(true);
    };
  
  return (
    <Box>
      <Swiper
        slidesPerView={4}
        spaceBetween={10}
        navigation={true}
        mousewheel={true}
        keyboard={true}
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        className="mySwiper"
        breakpoints={{
      
          320: {
            slidesPerView: 2, 
          },
          480: {
            slidesPerView: 2, 
          },
          680: {
            slidesPerView: 3, 
          },
          768: {
            slidesPerView: 4, 
          },
          900: {
            slidesPerView: 5, 
          },
          1024: {
            slidesPerView: 5, 
          },
          1224: {
            slidesPerView: 4, 
          },
          1536: {
            slidesPerView: 3, 
          },
          1700: {
            slidesPerView: 4, 
          },
        }}
      >
        {restuinfo?.rstbr_images?.map((rsImage, index) => (
          <SwiperSlide key={index}>
            <img
              src={rsImage}
              alt=""
              style={{
                width: '140px',
                height: '140px',
                borderRadius: '10px',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                 cursor: 'pointer'
              }}
              onClick={() => openLightbox(index)} 
            />
          </SwiperSlide>
        ))}
      </Swiper>

      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images?.length]}
          prevSrc={images[(photoIndex + images?.length - 1) % images?.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images?.length - 1) % images?.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}


    </Box>
  );
}
