import * as yup from 'yup';
const phoneRegExp = /(^([+]{1}[8]{2}|0088)?(01){1}[3-9]{1}\d{8})$/;
const emailValidation = yup.string().email().required('Email is required');
const titleEvent = yup.string().required('Title is required');
const phoneValidation = yup.string().required('Phone number is required').max(11).min(11).matches(phoneRegExp, 'Phone number is not valid');
const firstName = yup.string().required('First Name is  required');
// const fullName = yup.string().required('Full Name is  required');
const lastName = yup.string().required('Last Name is  required');
const genderValidation = yup.string().required('Gender is required');
const passwordValidation = yup.string().required('Password is required').min(6, 'Password must be at least 8 characters long');
// const dobValidation = yup.string().required('Date Of Birth is required');
const dateValidation = yup.string().required('Date is required');
const start_time = yup.string().required('Starting time is required');
const end_time = yup.string().required('Ending time is required');
const timeValidation = yup.string().required('Time is required');
const locationValidation = yup.string().required('Address is required');
const purposeValidation = yup.string().required('Purpose is required');
const leaveTypeValidation = yup.string().required('Leave Type is required');
const startDateValidation = yup.string().required('Starting Day is required');
const endDateValidation = yup.string().required('Ending Day is required');
const reasonValidation = yup.string().required('Reason is required');
const expectedDaysValidation = yup.string().required('Expected Days is required');
const applyAmountValidation = yup.string().required('Apply Amount is required');
const amountValidation = yup.string().required('Amount is required');
const referenceValidation = yup.string().required('Reference is required');
const emiLengthValidation = yup.string().required('Emi Length is required');
const loanTypeValidation = yup.string().required('Loan Type is required');
const eventType = yup.string().required('Event Type is required');
const intimeValidation = yup.string().required('In Time is required');
const outtimeValidation = yup.string().required('Out Time is required');
const typeValidation = yup.string().required('Type is required');
const durationValidation = yup.string().required('Duration is required');
const bankName = yup.string().required('Bank is required');
const bankState = yup.string().required('State is required');
const branch = yup.string().required('Branch is required');
const banking_services = yup.string().required('Bank Services is required');
const services = yup.string().required('Services is required');
const date = yup.string().required('date is required');
const times = yup.string().required('schedule is required');
const duration = yup.string().required('Maximum duration is 1 hour');
const city = yup.string().required('City is required');
const area = yup.string().required('Area is required');
const diagnosId = yup.string().required('Diagnostic/Hospital selection is required');
const dining  = yup.string().required('Required Dining regular/buffet')
const phone = yup.string().required('Phone Number is required')
const resDate = yup.string().required('date is required ')
const resPerson = yup.string().required('Person is Required ').min(1, 'minimum 1 person is required')
export const registration = yup.object().shape({
  first_name: firstName,
  // last_name: lastName,
  phone: phoneValidation,
  email: emailValidation,
  // gender: genderValidation,
  // dob: dobValidation,
  password: passwordValidation
});
export const addMeetings = yup.object().shape({
  phone: phoneValidation
});

export const addForm = yup.object().shape({
  date: dateValidation,
  time: timeValidation,
  purpose_id: purposeValidation,
  location: locationValidation,
  duration:duration
});

export const editAdd = yup.object().shape({
  title: titleEvent,
  start_time: start_time,
  end_time: end_time,
  location: locationValidation,
  date: dateValidation,
  // details: details,
  evnt_type_id: eventType
});

export const singleInvite = yup.object().shape({
  phone: phoneValidation
});

export const visitorPage = yup.object().shape({
  first_name: firstName,
  last_name: lastName,
  phone: phoneValidation,
  email: emailValidation,
  gender: genderValidation
});

export const leavePage = yup.object().shape({
  leave_category: leaveTypeValidation,
  fromdate: startDateValidation,
  todate: endDateValidation,
  leave_note: reasonValidation
});

export const earnWagePage = yup.object().shape({
  leave_category: expectedDaysValidation,
  apply_amount: applyAmountValidation
});

export const iouPage = yup.object().shape({
  amount: amountValidation
});

export const iouApplicationPage = yup.object().shape({
  date: dateValidation,
  amount: amountValidation,
  ref: referenceValidation
});

export const loanPage = yup.object().shape({
  emi_length: emiLengthValidation,
  amount: amountValidation,
  loan_type_id: loanTypeValidation
});

export const attendanceClaim = yup.object().shape({
  date: dateValidation,
  intime: intimeValidation,
  outtime: outtimeValidation
});
export const forget = yup.object().shape({
  phone: phoneValidation
});

export const scheduleValidation = yup.object().shape({
  date: dateValidation,
  start_time: start_time,
  end_time: end_time,
  type: typeValidation,
  duration:durationValidation
});
export const bankValidation = yup.object().shape({
  bank_code: bankName,
  state_name: bankState,
  branch_id: branch,
  service_type: banking_services,
  service_name:services,
  datex:date,
  time:times,
});
export const doctorValidation = yup.object().shape({
  // phone:phoneValidation,
  city : city,
  area :area,
  // diagnos_id : diagnosId,
  // branch_id : area,
  // date : area,
  // start_time : area,
  // end_time : area,
  // app_type : area, //others,myself
  // patient_name  : area,
  // patient_phone : area,
  // patient_type : area
});


export const restaurantValidation = yup.object().shape({
  bokfor: dining,
  emob: phone,
  date: resDate,
  numguests: resPerson,
});