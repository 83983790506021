import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import { DatePicker } from 'antd';
import { toast } from 'react-toastify';
import axiosInstance from 'utils/axios.config';
import FormControl from '@mui/material/FormControl';
import { useAppContext } from 'AppContextProvider';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography, Box, Grid, Divider, Button,FormControlLabel,RadioGroup ,TextField , Radio} from '@mui/material';


import ResUsers from 'components/svg/ResUsers';
import { restaurantValidation } from 'components/validation/Validation';


export default function AddRestaurant({ tablelists, servicelists, restuinfo, timelists }) {

  const { profile } = useAppContext();
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [personValue, setPersonValue] = useState('');
  const [selectedSlot, setSelectedSlot] = useState(null);
  const availableSlots = [];

  const isMatch = servicelists?.find((serList) => selectedValue === `${serList.srvtyp}-${serList.srvid}`);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm({
      resolver: yupResolver(restaurantValidation)
    });

  const getDayName = (date) => ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'][new Date(date).getDay()];
  const availableTime = timelists?.[getDayName(selectedDate?.format('YYYY-MM-DD'))];
  console.log( 'availableTime' , availableTime)

  function getTimeSlots(time) {
    const slots = [];
    let currentTime = new Date(`1970-01-01T${time.guest_stime}`);
    const end = new Date(`1970-01-01T${time.guest_end_time}`);

    if (end <= currentTime) {
      end.setDate(end.getDate() + 1);
    }

    while (currentTime < end) {
      const slotStart = currentTime.toTimeString().slice(0, 8);
      currentTime = new Date(currentTime.getTime() + 30 * 60000);
      const slotEnd = currentTime.toTimeString().slice(0, 8);

      slots.push({
        guest_stime: slotStart,
        guest_end_time: slotEnd
      });
    }

    return slots;
  }

  // const guestTimes = {
  //   guest_stime: "22:30:00",
  //   guest_end_time: "12:30:00"
  // };

  // const timeSlots = getTimeSlots(guestTimes);
  // // console.log(timeSlots);


  availableTime?.forEach((time) => {
    const aSlots = getTimeSlots(time);
    availableSlots.push(...aSlots);
  });


 

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    const [srvtyp, srvid] = value.split('-');
    setValue('bokfor', srvtyp);
    setValue('srvidx', srvid);
  };

  const handleBoxClick = (id) => {
    setSelectedId(id);
  };

  const personHandleChange = (event) => {
    setPersonValue(event.target.value);
  };

  const handleBookList = () => {
    navigate('/booking/bookingLists');
  };

  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
  };

  const onSubmit = (data) => {
    // data.arrivaltime = selectedArrivalTime.format('HH:mm');
    data.arrivaltime = selectedSlot;
    const formattedDate = dayjs(data.date).format('YYYY-MM-DD');
    data.date = formattedDate;

    axiosInstance.post('https://api.hellokompass.com/rsetubook', data).then((res) => {
      if (res.data.code === 200) {
        toast.success(res.data.data.message);
        handleBookList();
        // navigate('/appointment');
        // reset();
      } else if (res.data.code === 400) {
        toast.failed(res.data.message);
        // reset();
      } else {
        <></>;
      }
    });
  };
    const convertTo12HourFormat = (time24) => {
    const [hours, minutes] = time24.split(':');
    const parsedHours = parseInt(hours, 10);
    const suffix = parsedHours >= 12 ? 'PM' : 'AM';
    let convertedHours = parsedHours % 12;
    convertedHours = convertedHours === 0 ? 12 : convertedHours;
    return `${convertedHours}:${minutes} ${suffix}`;
  };



  return (
    <Box sx={{  border: '1px solid #f1f1f1' }}>
      <Box sx={{ width: '100%', textAlign: 'center', py: 2 }}>
        <Typography sx={{ color: '#4e4d4e', fontSize: '20px', fontWeight: 'bold', fontFamily: 'poppins' }}>Restaurant Booking</Typography>
      </Box>

      <Divider variant="middle" />
      <Box sx={{ mx: 2 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Typography sx={{ color: '#4e4d4e', fontSize: '14px', fontWeight: 'bold', fontFamily: 'poppins', mt: 2 }}>
              Select Dining
            </Typography>

            <Box
              sx={{
                display: 'flex',
                gap: '16px',
                flexWrap: 'wrap'
              }}
            >
              {servicelists?.map((serList) => (
                <Box key={serList.srvid} sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                  <FormControl>
                    <RadioGroup
                      {...register('bokfor', { required: true })}
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={selectedValue}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        // value={serList.srvtyp  }
                        value={`${serList.srvtyp}-${serList.srvid}`}
                        sx={{
                          fontWeight: 'bold',
                          fontFamily: 'Poppins'
                        }}
                        control={
                          <Radio
                            sx={{
                              color: '#12a9b2',
                              '&.Mui-checked': {
                                color: '#12a9b2'
                              }
                            }}
                          />
                        }
                        label={serList.srvtyp.charAt(0).toUpperCase() + serList.srvtyp.slice(1)}
                      />
                    </RadioGroup>
                   
                  </FormControl>
                </Box>
               
              ))}
            </Box>
            <Typography sx={{ color: '#FF0000', fontSize: '12px' }}>{errors.bokfor?.message}</Typography>
          </Box>

          <Box sx={{ mb: 2 }} hidden>
            {/* <Typography sx={{ color: '#4e4d4e', fontSize: '14px', fontWeight: 'bold', fontFamily: 'poppins', mt: 1 }}>Phone</Typography> */}
            <TextField
              {...register('srvidx', { required: true })}
              id="outlined-basic"
              variant="outlined"
              sx={{
                mt: 1,
                '& .MuiOutlinedInput-root': {
                  borderRadius: 0
                },
                width: '100%'
              }}
            />
          </Box>

          {profile?.person_phone_no && (
            <Box sx={{ mb: 2 }}>
              <Typography sx={{ color: '#4e4d4e', fontSize: '14px', fontWeight: 'bold', fontFamily: 'poppins', mt: 1 }}>Phone</Typography>
              <TextField
                {...register('emob', { required: true })}
                id="outlined-basic"
                variant="outlined"
                value={profile?.person_phone_no}
                sx={{
                  mt: 1,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 0
                  },
                  width: '100%'
                }}
              />
                     <Typography sx={{ color: '#FF0000', fontSize: '12px' }}>{errors.emob?.message}</Typography>
            </Box>
          )}

          <Box sx={{ mb: 2 }}>
            <Typography sx={{ color: '#4e4d4e', fontSize: '14px', fontWeight: 'bold', fontFamily: 'poppins', mb: 1 }}>
              Select Date
            </Typography>

            <Box>
              <FormControl className="maxWidth">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    name="date"
                    {...register('date')}
                    value={selectedDate}
                    onChange={handleDateChange}
                    format="YYYY-MM-DD"
                    style={{
                      width: '100%',
                      borderRadius: 0,
                      paddingTop: '10px',
                      paddingBottom: '10px'
                    }}
                  />
                </LocalizationProvider>
                <Typography sx={{ color: '#FF0000', fontSize: '12px' }}>{errors.date?.message}</Typography>
              </FormControl>
            </Box>
          </Box>
      

          <Controller
            name="arrivaltime"
            control={control}
            defaultValue={null}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Box>
                {availableSlots.map((slot) => (
                  <Button
                    key={slot.guest_stime}
                    sx={{
                      color: value === slot.guest_stime ? '#fff' : '#12a9b2',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      border: 1,
                      px: '12px',
                      py: '4px',
                      mx: '5px',
                      my: '6px',
                      bgcolor: value === slot.guest_stime ? '#12a9b2' : 'transparent',
                      '&:hover': {
                        bgcolor: value === slot.guest_stime ? '#0e7a81' : '#f0f8f8',
                        color: value === slot.guest_stime ? '#fff' : '#0e7a81'
                      }
                    }}
                    onClick={() => {
                      setSelectedSlot(slot.guest_stime);
                      onChange(slot.guest_stime); // Update form value
                    }}
                  >
                    {convertTo12HourFormat(slot.guest_stime)}
                  </Button>
                ))}
                {error && <Typography sx={{ color: 'red', mt: 1, fontSize: '12px' }}>{error.message}</Typography>}
              </Box>
            )}
          />

          {/* 
          <Box>
            <Typography sx={{ color: '#4e4d4e', fontSize: '14px', fontWeight: 'bold', fontFamily: 'poppins', mt: 2 }}>
              Select Time
            </Typography>

            <FormControl sx={{ mt: 1, width: '100%', borderRadius: '0' }} fullWidth>
              <Box sx={{ borderRadius: '0' }}>
                <Controller
                  name="arrivaltime"
                  control={control}
                  defaultValue={selectedArrivalTime}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileTimePicker
                        sx={{ width: '100%', borderRadius: '0' }}
                        showSecond={false}
                        value={field.value}
                        onChange={(newValue) => {
                          field.onChange(newValue);
                          setSelectedArrivalTime(newValue);
                        }}
                        format="h:mm a"
                        use12Hours
                        name="arrivaltime"
                      />
                    </LocalizationProvider>
                  )}
                />
              </Box>

              <Typography sx={{ color: '#FF0000', fontSize: '12px' }}>{errors.start_time?.message}</Typography>
            </FormControl>
          </Box> */}

          <Box>
            <Typography sx={{ color: '#4e4d4e', fontSize: '14px', fontWeight: 'bold', fontFamily: 'poppins', mt: 1 }}>Person</Typography>
            <TextField
              {...register('numguests', { required: true })}
              value={personValue}
              onChange={personHandleChange}
              type="number"
              id="outlined-basic"
              variant="outlined"
              placeholder="Enter Person"
              sx={{
                mt: 1,
                '& .MuiOutlinedInput-root': {
                  borderRadius: 0
                },
                width: '100%'
              }}
            />
          <Typography sx={{ color: '#FF0000', fontSize: '12px' }}>{errors.numguests?.message}</Typography>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography sx={{ color: '#4e4d4e', fontSize: '14px', fontWeight: 'bold', fontFamily: 'poppins', mt: 2 }}>
              Special Note
            </Typography>
            <TextField
              {...register('notx', { required: true })}
              rows={2}
              multiline
              id="outlined-basic"
              variant="outlined"
              placeholder="Special Note"
              sx={{
                mt: 1,
                '& .MuiOutlinedInput-root': {
                  borderRadius: 0
                },
                width: '100%'
              }}
            />
          </Box>

          {restuinfo?.rstid && (
            <Box sx={{ mt: 2 }} hidden>
              <Typography sx={{ color: '#4e4d4e', fontSize: '14px', fontWeight: 'bold', fontFamily: 'poppins', mt: 2 }}>rstid</Typography>
              <TextField
                {...register('rstid', { required: true })}
                value={restuinfo?.rstid}
                id="outlined-basic"
                variant="outlined"
                sx={{
                  mt: 1,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 0
                  },
                  width: '100%'
                }}
              />
            </Box>
          )}

          {restuinfo?.rstbrid && (
            <Box sx={{ mt: 2 }} hidden>
              <Typography sx={{ color: '#4e4d4e', fontSize: '14px', fontWeight: 'bold', fontFamily: 'poppins', mt: 2 }}>rstbrid</Typography>
              <TextField
                {...register('rstbrid', { required: true })}
                value={restuinfo?.rstbrid}
                id="outlined-basic"
                variant="outlined"
                sx={{
                  mt: 1,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 0
                  },
                  width: '100%'
                }}
              />
            </Box>
          )}

          {isMatch?.srvid === '4' ? (
            <>
              <Typography sx={{ color: '#4e4d4e', fontSize: '14px', fontWeight: 'bold', fontFamily: 'poppins', mt: 2 }}>
                Select Table
              </Typography>
              <Box sx={{ mt: 2 }}>
                {tablelists?.map((taList) => (
                  <Box
                    key={taList.tblcode}
                    onClick={() => handleBoxClick(taList.tblid)}
                    sx={{
                      display: 'flex',
                      gap: 2,
                      border: '1px solid #f1f1f1',
                      mt: 1,
                      py: 1,
                      boxShadow: 1,
                      cursor: 'pointer',
                      backgroundColor: selectedId === taList.tblid ? '#e0f7fa' : 'transparent'
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={5} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                          sx={{
                            ml: '-30px',
                            '& ol': {
                              listStyleType: 'none',
                              marginTop: '0',
                              marginBottom: '0',
                              color: '#4e4d4e',
                              fontSize: '12px',
                              fontWeight: 'bold',
                              fontFamily: 'poppins'
                            }
                          }}
                          dangerouslySetInnerHTML={{ __html: taList.tbldetails }}
                          component="div"
                        ></Typography>
                      </Grid>
                      <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ color: '#4e4d4e', fontSize: '13px', fontWeight: 'bold', fontFamily: 'poppins' }}>
                          <span style={{ color: '#333', fontSize: '13px', fontWeight: 'bold', fontFamily: 'poppins' }}>t</span>
                          {taList.tblcode}
                        </Typography>
                      </Grid>
                      <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex' }}>
                          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <ResUsers></ResUsers>
                          </Box>
                          <Box sx={{ mt: '3px' }}>
                            <Typography sx={{ color: '#4e4d4e', fontSize: '13px', fontWeight: 'bold', fontFamily: 'poppins' }}>
                              {taList.tblprson}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <img src={taList.tblimg} alt="image" style={{ width: '52px', height: '52px' }} />
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <img src={taList.tblviewimg} alt="image" style={{ width: '52px', height: '52px' }} />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                ))}
              </Box>
            </>
          ) : (
            <></>
          )}
          {selectedId && (
            <Box sx={{ mb: 2 }} hidden>
              <TextField
                {...register('tblid', { required: true })}
                id="outlined-basic"
                variant="outlined"
                value={selectedId}
                sx={{
                  mt: 1,
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 0
                  },
                  width: '100%'
                }}
              />
            </Box>
          )}
          <Box>
            <Button
              variant="contained"
              type="submit"
              size="medium"
              sx={{
                width: '100%',
                mt: 2,
                mb: 2,
                color: '#FFF',
                borderRadius: 0,
                backgroundColor: '#12a9b2',
                fontSize: '14px',
                fontWeight: 'bold',
                fontFamily: 'poppins',

                '&:hover': { backgroundColor: '#0e8087', color: '#FFF' }
              }}
            >
              Confirm Booking
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
}
