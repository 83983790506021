import MainCard from 'components/MainCard';
import React, { useEffect, useState } from 'react';
import { Typography, Box, Grid } from '@mui/material';
import axiosInstance from 'utils/axios.config';
import ResBookingList from './ResBookingList';
export default function ResBookingLists() {
  const [resBookingList, setResBookingList] = useState([]);
  // console.log('resBooking', resBookingList);

  useEffect(() => {
    axiosInstance
      .get('https://api.hellokompass.com/restubooklists')
      .then((res) => {
        setResBookingList(res.data.data);
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <MainCard>
      <Box>
        <Box>
          <Typography
            sx={{
              fontWeight: 'bold',
              fontFamily: 'Poppins',
              fontSize: '23px',
              color: '#0C1E21',
              mb: '20px',
              pb: '20px',
              borderBottom: 1,
              borderColor: '#f1f1f1'
            }}
          >
            Restaurant Booking List
          </Typography>
        </Box>

        <Box>
          <Grid container spacing={2}>
            {resBookingList.map((resBook) => (
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
                <Box key={resBook.rboksl} sx={{ border: '1px solid #f1f1f1', borderRadius: '20px' }}>
                  <ResBookingList resBook={resBook}></ResBookingList>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

      </Box>
    </MainCard>
  );
}
