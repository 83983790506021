import Loadable from 'components/Loadable';
import { Suspense, lazy } from 'react';
import { Typography } from '@mui/material';
import AddProperty from 'pages/components-overview/agentBoard/property/AddPropertyOwnerModal';
import OwnerList from 'pages/components-overview/agentBoard/ownerList/OwnerList';
import OwnerPropertyList from 'pages/components-overview/agentBoard/owner/OwnerPropertyList';
import AddProOwner from 'pages/components-overview/agentBoard/owner/AddProOwner';
import FavoritePlace from 'pages/components-overview/appointment/favoritePlace/FavoritePlace';
import PasswordChanged from 'components/modal/profile/PasswordChanged';
import ApartmentInfoLists from 'pages/components-overview/agentBoard/ownerList/ApartmentInfoLists';
import RestaurantDetails from 'pages/components-overview/booking/restaurant/RestaurantDetails';
import ResBookingList from 'pages/components-overview/booking/restaurant/ResBookingLists';
import ResBookingLists from 'pages/components-overview/booking/restaurant/ResBookingLists';


const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));
const Appointment = Loadable(lazy(() => import('pages/components-overview/appointment/Appointment')));
const Event = Loadable(lazy(() => import('pages/components-overview/event/Event')));
const MeetingDetails = Loadable(lazy(() => import('pages/components-overview/appointment/MeetingDetails')));
const ViewProfile = Loadable(lazy(() => import('components/modal/profile/ViewProfile')));
const EditProfile = Loadable(lazy(() => import('components/modal/profile/EditProfile')));
const ChangePassword = Loadable(lazy(() => import('components/modal/profile/PasswordChanged')));
const MainLayout = Loadable(lazy(() => import('layout/MainLayout')));
const NotFoundPage = Loadable(lazy(() => import('pages/components-overview/NotFoundPage/NotFoundPage')));
const LeaveList = Loadable(lazy(() => import('pages/components-overview/leave/LeaveList')));
const EarnWage = Loadable(lazy(() => import('pages/components-overview/earnwage/EarnWage')));
const EarnWageList = Loadable(lazy(() => import('pages/components-overview/earnwage/EarnWageList')));
const Loan = Loadable(lazy(() => import('pages/components-overview/loan/Loan')));
const LoanList = Loadable(lazy(() => import('pages/components-overview/loan/LoanList')));
const GuestList = Loadable(lazy(() => import('pages/components-overview/event/GuestList')));
const EventDetails = Loadable(lazy(() => import('pages/components-overview/event/EventDetails')));
const AddEvent = Loadable(lazy(() => import('pages/components-overview/event/AddEvent')));
const EditEvent = Loadable(lazy(() => import('pages/components-overview/event/EditEvent')));
const Notification = Loadable(lazy(() => import('pages/components-overview/Notification')));
const LeaveApply = Loadable(lazy(() => import('pages/components-overview/leave/LeaveApply')));
const SettlementList = Loadable(lazy(() => import('pages/components-overview/iou/SettlementList')));
const Iou = Loadable(lazy(() => import('pages/components-overview/iou/Iou')));
const EmiDetails = Loadable(lazy(() => import('pages/components-overview/loan/EmiDetails')));
const SalaryCertificate = Loadable(lazy(() => import('pages/components-overview/salary/SalaryCertificate')));
const Salary = Loadable(lazy(() => import('pages/components-overview/salary/Salary')));
const AddForm = Loadable(lazy(() => import('pages/components-overview/appointment/AddForm')));
const VisitorPage = Loadable(lazy(() => import('pages/components-overview/appointment/VisitorPage')));
const PrivateRoutes = Loadable(lazy(() => import('components/privateRoute/PrivateRoutes')));
const ProvidentFund = Loadable(lazy(() => import('pages/components-overview/providentfund/ProvidentFund')));
const Support = Loadable(lazy(() => import('pages/components-overview/support/Support')));
const Schedule = Loadable(lazy(() => import('pages/components-overview/Schedule/Schedule')));
const AddSchedule = Loadable(lazy(() => import('pages/components-overview/Schedule/AddSchedule')));
const AttendanceClaim = Loadable(lazy(() => import('pages/components-overview/attendance/AttendanceClaim')));
const AttendanceHistory = Loadable(lazy(() => import('pages/components-overview/attendance/AttendanceHistory')));
const ApplyIou = Loadable(lazy(() => import('pages/components-overview/iou/ApplyIou')));
const IouList = Loadable(lazy(() => import('pages/components-overview/iou/IouList')));
const Document = Loadable(lazy(() => import('pages/components-overview/Document')));
const HotelBook = Loadable(lazy(() => import('pages/components-overview/booking/hotel/HotelBook')));
const HotelCheckIn = Loadable(lazy(() => import('pages/components-overview/booking/hotel/HotelCheckIn')));
const HotelDetails = Loadable(lazy(() => import('pages/components-overview/booking/hotel/HotelDetails')));
const Reserve = Loadable(lazy(() => import('pages/components-overview/booking/hotel/Reserve')));
const AddBankAppointment = Loadable(lazy(() => import('pages/components-overview/bankAppointment/AddBankAppointment')));
const BankAppointment = Loadable(lazy(() => import('pages/components-overview/bankAppointment/BankAppointment')));
const BookingList = Loadable(lazy(() => import('pages/components-overview/booking/hotel/BookingList')));
const DeactivateAccount = Loadable(lazy(() => import('pages/components-overview/deactivateAccount/DeactivateAccount')));
const Booking = Loadable(lazy(() => import('pages/components-overview/booking/Booking')));
const Restaurant = Loadable(lazy(() => import('pages/components-overview/booking/restaurant/Restaurant')));
const EmployeeList = Loadable(lazy(() => import('pages/components-overview/appointment/EmployeeList')));
const EmailVerificationCode = Loadable(lazy(() => import('pages/emailVerification/EmailVerificationCode')));
const DoctorsAppointmentList = Loadable(lazy(() => import('pages/components-overview/booking/doctorAppointment/DoctorsAppointmentList')));
const DrAppointmentCheckIn = Loadable(
  lazy(() => import('pages/components-overview/booking/doctorAppointment/multiStepForm/DrAppointmentCheckin/DrAppointmentCheckIn'))
);
const DoctorsAppointmentLists = Loadable(lazy(() => import('pages/components-overview/booking/doctorAppointment/DoctorsAppointmentLists')));
const FindHospital = Loadable(lazy(() => import('pages/components-overview/booking/doctorAppointment/FindHospital')));
const Approval = Loadable(lazy(() => import('pages/components-overview/payroll/approval/Approval')));
// ==============================|| properties ROUTING ||============================== //

const Property = Loadable(lazy(() => import('pages/components-overview/agentBoard/Property')));
const PropertyAgent = Loadable(lazy(() => import('pages/components-overview/agentBoard/propertyAgent/PropertyAgent')));
const AgentPortfolio = Loadable(lazy(() => import('pages/components-overview/agentBoard/AgentPortfolio')));
const AddPropertyAgent = Loadable(lazy(() => import('pages/components-overview/agentBoard/propertyAgent/AddPropertyAgentModal')));
const PropertyList = Loadable(lazy(() => import('pages/components-overview/agentBoard/PropertyList')));
const Properties = Loadable(lazy(() => import('pages/components-overview/agentBoard/property/Properties')));

const MainRoutes = {
  path: '/',
  element: (
    <Suspense fallback={<Typography>Loading...</Typography>}>
      <PrivateRoutes>
        <MainLayout />
      </PrivateRoutes>
    </Suspense>
  ),
  children: [
    {
      path: '/',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          <DashboardDefault />
        </Suspense>
      )
    },
    {
      path: 'dashboard',
      children: [
        {
          path: '',
          element: (
            <PrivateRoutes>
              <DashboardDefault />
            </PrivateRoutes>
          )
        }
      ]
    },
    {
      path: 'appointment',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          {' '}
          <PrivateRoutes>
            <Appointment />{' '}
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'appointment/addForm',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          <PrivateRoutes>
            <AddForm />
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'favoritePlace',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          <PrivateRoutes>
            <FavoritePlace></FavoritePlace>
          </PrivateRoutes>
        </Suspense>
      )
    },

    {
      path: 'appointment/visitorPage',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          <PrivateRoutes>
            <VisitorPage />
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'event',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          <PrivateRoutes>
            <Event />
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'event/guestList',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          <PrivateRoutes>
            <GuestList />
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'event/eventDetails',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          <PrivateRoutes>
            <EventDetails />
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'event/addEvent',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          <PrivateRoutes>
            <AddEvent />
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'schedule',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          <PrivateRoutes>
            <Schedule />
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'event/:idxe',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          <PrivateRoutes>
            <EditEvent />
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'appointment/checkPhone',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          <PrivateRoutes>
            <MeetingDetails />
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'appointment/checkPhone/employeeList',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          <PrivateRoutes>
            <EmployeeList></EmployeeList>
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'notification',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          <PrivateRoutes>
            <Notification />
          </PrivateRoutes>
        </Suspense>
      )
    },

    {
      path: 'profile/viewProfile',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          <PrivateRoutes>
            <ViewProfile />
          </PrivateRoutes>
        </Suspense>
      )
    },

    {
      path: 'profile/emailVerification',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          <PrivateRoutes>
            <EmailVerificationCode />
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'profile/passwordChanged',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          <PrivateRoutes>
            <PasswordChanged />
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'profile/editProfile',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          <PrivateRoutes>
            <EditProfile />
          </PrivateRoutes>{' '}
        </Suspense>
      )
    },

    {
      path: 'earnWage',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          <PrivateRoutes>
            <EarnWage />
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'leaveApply',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          <PrivateRoutes>
            <LeaveApply />
          </PrivateRoutes>
        </Suspense>
      )
    },

    {
      path: 'iou',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          <PrivateRoutes>
            <IouList />
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'apply',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          <PrivateRoutes>
            <ApplyIou />
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'iou/settlementList',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          <PrivateRoutes>
            <SettlementList />
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'iou/settlement',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          <PrivateRoutes>
            <Iou />
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'leave/list',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          <PrivateRoutes>
            <LeaveList />
          </PrivateRoutes>
        </Suspense>
      )
    },

    {
      path: 'earnWage/list',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          <PrivateRoutes>
            {' '}
            <EarnWageList />
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'loan',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          <PrivateRoutes>
            <Loan />
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'loan/list',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          <PrivateRoutes>
            <LoanList />
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'loan/list/emiDetails',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          <PrivateRoutes>
            <EmiDetails />
          </PrivateRoutes>
        </Suspense>
      )
    },

    {
      path: 'salary/salaryCertificate',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          <PrivateRoutes>
            <SalaryCertificate />
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'salary/salaryList',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          <PrivateRoutes>
            <Salary />
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'providentFund/list',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          <PrivateRoutes>
            <ProvidentFund />
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'attendanceClaim',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          <PrivateRoutes>
            <AttendanceClaim />
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'attendanceHistory',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          <PrivateRoutes>
            <AttendanceHistory />
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: '*',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          {' '}
          <PrivateRoutes>
            <NotFoundPage />
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'support',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          {' '}
          <PrivateRoutes>
            <Support />
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'addSchedule',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          {' '}
          <PrivateRoutes>
            <AddSchedule />
          </PrivateRoutes>
        </Suspense>
      )
    },

    {
      path: 'document',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          {' '}
          <PrivateRoutes>
            <Document />
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'booking',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          {' '}
          <PrivateRoutes>
            <Booking />
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'booking/hotelBooking',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          {' '}
          <PrivateRoutes>
            <HotelBook />
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'booking/hotelBooking/reserve/BookingList',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          {' '}
          <PrivateRoutes>
            <BookingList />
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'booking/hotel_Check_In',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          {' '}
          <PrivateRoutes>
            <HotelCheckIn />
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'booking/hotelDetails',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          {' '}
          <PrivateRoutes>
            <HotelDetails></HotelDetails>
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'booking/reserve',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          {' '}
          <PrivateRoutes>
            <Reserve></Reserve>
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'bankAppointment',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          {' '}
          <PrivateRoutes>
            <BankAppointment></BankAppointment>
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'bankAppointment/addAppointment',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          {' '}
          <PrivateRoutes>
            <AddBankAppointment></AddBankAppointment>
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'booking/restaurant',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          {' '}
          <PrivateRoutes>
            <Restaurant></Restaurant>
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'booking/restaurantDetails/:res_id',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          {' '}
          <PrivateRoutes>
            <RestaurantDetails></RestaurantDetails>
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'booking/bookingLists',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          {' '}
          <PrivateRoutes>
   <ResBookingLists></ResBookingLists>
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'profile/deactivateAccount',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          {' '}
          <PrivateRoutes>
            <DeactivateAccount></DeactivateAccount>
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: 'booking/doctorsAppointment',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          {' '}
          <PrivateRoutes>
            <FindHospital></FindHospital>
          </PrivateRoutes>
        </Suspense>
      )
    },

    {
      path: 'booking/doctorsAppointment/doctorsAppointmentLists',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          {' '}
          <PrivateRoutes>
            <DoctorsAppointmentLists></DoctorsAppointmentLists>
          </PrivateRoutes>
        </Suspense>
      )
    },

    {
      path: 'booking/doctorsAppointmentList',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          {' '}
          <PrivateRoutes>
            <DoctorsAppointmentList></DoctorsAppointmentList>
          </PrivateRoutes>
        </Suspense>
      )
    },

    {
      path: 'booking/drAppointmentCheckIn',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          {' '}
          <PrivateRoutes>
            <DrAppointmentCheckIn></DrAppointmentCheckIn>
          </PrivateRoutes>
        </Suspense>
      )
    },

    {
      path: '/approval',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          {' '}
          <PrivateRoutes>
            <Approval></Approval>
          </PrivateRoutes>
        </Suspense>
      )
    },

    {
      path: '/property',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          {' '}
          <PrivateRoutes>
            <Property></Property>
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: '/property/propertyList',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          {' '}
          <PrivateRoutes>
            <PropertyList></PropertyList>
          </PrivateRoutes>
        </Suspense>
      )
    },

    {
      path: '/property/propertyList/propertyAgent',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          {' '}
          <PrivateRoutes>
            <PropertyAgent></PropertyAgent>
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: '/property/propertyList/agentPortfolio',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          {' '}
          <PrivateRoutes>
            <AgentPortfolio></AgentPortfolio>
          </PrivateRoutes>
        </Suspense>
      )
    },
    // {
    //   path: '/property/propertyList/propertyAgent/addPropertyAgent',
    //   element: (
    //     <Suspense fallback={<Typography>Loading...</Typography>}>
    //       {' '}
    //       <PrivateRoutes>
    //         <AddPropertyAgent></AddPropertyAgent>
    //       </PrivateRoutes>
    //     </Suspense>
    //   )
    // },
    {
      path: '/property/propertyList/properties',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          {' '}
          <PrivateRoutes>
            <Properties></Properties>
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: '/property/propertyList/properties/addProperty',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          {' '}
          <PrivateRoutes>
            <AddProperty></AddProperty>
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: '/property/ownerList',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          {' '}
          <PrivateRoutes>
            <OwnerList></OwnerList>
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: '/property/ownerList/apartmentList/:building_id',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          <PrivateRoutes>
            <ApartmentInfoLists></ApartmentInfoLists>
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: '/property/ownerList/ownerPropertyList',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          {' '}
          <PrivateRoutes>
            <OwnerPropertyList></OwnerPropertyList>
          </PrivateRoutes>
        </Suspense>
      )
    },
    {
      path: '/property/ownerList/ownerPropertyList/addProOwner',
      element: (
        <Suspense fallback={<Typography>Loading...</Typography>}>
          {' '}
          <PrivateRoutes>
            <AddProOwner></AddProOwner>
          </PrivateRoutes>
        </Suspense>
      )
    }
  ]
};

export default MainRoutes;
