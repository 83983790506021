import React, { useState } from 'react';
import { Typography, Box, Grid, Divider, Button } from '@mui/material';
import View from 'components/svg/View';
import ModalRestaurant from './ModalRestaurant';
export default function ResBookingList({ resBook }) {
  const { rstbr_logo, bkstatus, bokfor, bkdates, arrivaltime, rboksl } = resBook;
  // console.log('resBook' , resBook)

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Box sx={{ px: 2, my: 2 }}>
      <Box>
        <img src={rstbr_logo} alt="rstbr_logo" style={{ width: '100%', height: '100%', paddingTop: '10px', boxShadow: 2 }} />
      </Box>
      <Box sx={{ border: '1px solid #f1f1f1', p: 1, borderRadius: '20px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* {bokfor === 'regular' &&} */}

          <Typography sx={{ fontWeight: 'medium', fontFamily: 'Poppins', fontSize: '14px', color: '#0C1E21', mb: '3px' }}>
            {' '}
            Dining : Regular
          </Typography>
          <Box onClick={handleOpen} sx={{ cursor: 'pointer' }}>
            <View></View>
          </Box>
        </Box>

        <Typography sx={{ fontWeight: 'medium', fontFamily: 'Poppins', fontSize: '14px', color: '#0C1E21', mb: '3px' }}>
          {' '}
          Booking Status : {bkstatus}
        </Typography>
        <Typography sx={{ fontWeight: 'medium', fontFamily: 'Poppins', fontSize: '14px', color: '#0C1E21', mb: '3px' }}>
          {' '}
          Booking Date : {bkdates}
        </Typography>
        <Typography sx={{ fontWeight: 'medium', fontFamily: 'Poppins', fontSize: '14px', color: '#0C1E21', mb: '3px' }}>
          {' '}
          Arrival Time : {arrivaltime}
        </Typography>
        {/* <Typography sx={{ fontWeight: 'medium', fontFamily: 'Poppins', fontSize: '13px', color: '#0C1E21', mb:'3px' }}> Special Note : {notx}</Typography> */}
      </Box>

      <ModalRestaurant open={open} handleClose={handleClose} rboksl={rboksl}></ModalRestaurant>
    </Box>
  );
}
