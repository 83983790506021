import React, { useEffect, useState } from 'react';
import { Typography, Box, Grid, ListItem } from '@mui/material';
import { useParams } from 'react-router-dom';
import axiosInstance from 'utils/axios.config';
import MainCard from 'components/MainCard';
import cuisine from '../../../../assets/images/image/dinner.png';
import pin from '../../../../assets/images/image/pin.png';
import dining from '../../../../assets/images/image/chair.png';
import AddRestaurant from './AddRestaurant';
import SwiperSlider from './SwiperSlider';
import SwiperSliderMenus from './SwiperSliderMenus';
export default function RestaurantDetails() {
  const [detailsPage, setDetailsPage] = useState([]);
  const { res_id } = useParams();

  var allDays = [];
  var allValues = [];

  const { restuinfo, servicelists, tablelists, timelists } = detailsPage;
  //  console.log('detailsPage', detailsPage);
  // const {rstbr_logo,rstbr_typ,rstbranch_name , rstbr_address} = restuinfo;
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;
  const getDayName = (date) => ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'][new Date(date).getDay()];
  const availableTime = timelists?.[getDayName(formattedDate)];

  if (typeof timelists === 'object' && timelists !== null) {
    Object.entries(timelists).map(([day, values]) => {
      allDays.push(day);
      allValues.push(values);
    });
  } else {
    console.error('Invalid timelists object');
  }

  useEffect(() => {
    axiosInstance
      .get(`https://api.hellokompass.com/restudetails/${res_id}`)
      .then((res) => {
        setDetailsPage(res?.data?.data);
      })
      .catch((error) => {
        console.error('Error fetching departments:', error);
      });
  }, [res_id]);

  const convertTo12HourFormat = (time24) => {
    const [hours, minutes] = time24.split(':');
    const parsedHours = parseInt(hours, 10);
    const suffix = parsedHours >= 12 ? 'PM' : 'AM';
    let convertedHours = parsedHours % 12;
    convertedHours = convertedHours === 0 ? 12 : convertedHours;
    return `${convertedHours}:${minutes} ${suffix}`;
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box>
      <MainCard>
        <Box sx={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
          <img src={restuinfo?.rstbr_coverimg} alt="" style={{ borderRadius: '16px', width: windowWidth <= 1000 ? '100%' : '68%' }} />

          <Box
            sx={{
              display: 'flex',
              gap: 2,
              position: 'absolute',
              bottom: '-100px',
              left: {
                xs: '',
                sm: '40px',
                md: '220px',
                lg: '280px'
              }
            }}
          >
            <Box>
              <img
                src={restuinfo?.rstbr_logo}
                alt=""
                style={{
                  borderRadius: '16px',
                  width: windowWidth <= 1000 ? '120px' : '140px',
                  height: windowWidth <= 1000 ? '120px' : '140px',
                  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
                }}
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mt: {
                  xs: 3,
                  md: 6
                }
              }}
            >
              <Box>
                <Typography sx={{ fontWeight: 'bolder', fontFamily: 'Poppins', fontSize: '16px', color: '#0C1E21' }}>
                  {restuinfo?.rstbranch_name}
                </Typography>
                <Typography sx={{ fontWeight: 'normal', fontFamily: 'Poppins', fontSize: '12px', color: '#0C1E21' }}>
                  {restuinfo?.rstbr_address}
                </Typography>

                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Typography>Available Time -</Typography>
                  <Box>
                    {Array.isArray(availableTime) && availableTime.length > 0 ? (
                      availableTime.map((time, index) => (
                        <Box key={index}>
                          <Typography sx={{ fontWeight: 'normal', fontFamily: 'Poppins', fontSize: '12px', color: '#0C1E21' }}>
                            {convertTo12HourFormat(time.guest_stime)} - {convertTo12HourFormat(time.guest_end_time)}
                          </Typography>
                        </Box>
                      ))
                    ) : (
                      <Typography sx={{ fontWeight: 'normal', fontFamily: 'Poppins', fontSize: '12px', color: 'red' }}>
                        No available times for today.
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            mx: {
              xs: 2,
              xl: 30
            },
            mt: 20
          }}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} md={7}>
              <Box sx={{}}>
                <Box>
                  <Typography sx={{ fontWeight: 'normal', fontFamily: 'Poppins', fontSize: '15px', color: '#0C1E21' }}>
                    {restuinfo?.rstbr_description}
                  </Typography>
                </Box>

                <Box sx={{ mt: 4 }}>
                  <Typography sx={{ fontWeight: 'bolder', fontFamily: 'Poppins', fontSize: '30px', color: '#0C1E21' }}>
                    Photos ({restuinfo?.rstbr_images?.length})
                  </Typography>
                  <Box sx={{ gap: 2, mt: 3 }}>
                    {/* {restuinfo?.rstbr_images?.slice(2).map((rsImage) => (
                    <Box >
                      <img src={rsImage} alt="" style={{width:'140px', height:'140px' , borderRadius:'10px' ,  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}  />
                    </Box>
                  ))} */}
                    <SwiperSlider restuinfo={restuinfo}></SwiperSlider>
                  </Box>
                </Box>

                <Box sx={{ mt: 4 }}>
                  <Typography sx={{ fontWeight: 'bolder', fontFamily: 'Poppins', fontSize: '30px', color: '#0C1E21' }}>
                    Menus ({restuinfo?.rstbr_menuimgs?.length})
                  </Typography>
                  <Box sx={{ gap: 2, mt: 3 }}>
                    {/* {restuinfo?.rstbr_menuimgs?.map((rsImage) => (
                    <Box >
                      <img src={rsImage} alt="rsImage" style={{width:'140px', height:'140px' , borderRadius:'10px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'}}  />
                    </Box>
                  ))} */}
                    <SwiperSliderMenus restuinfo={restuinfo}></SwiperSliderMenus>
                  </Box>
                </Box>

                <Box sx={{ mt: 8 }}>
                  <Typography sx={{ fontWeight: 'bolder', fontFamily: 'Poppins', fontSize: '30px', color: '#0C1E21' }}>
                    Opening Hours
                  </Typography>

                  <Box sx={{ display: 'flex', gap: 10 }}>
                    <Box sx={{ mt: 4 }}>
                      <Box sx={{ mt: '15px' }}>
                        {allDays?.map((day) => (
                          <Typography sx={{ fontWeight: 'medium', fontFamily: 'Poppins', fontSize: '14px', color: '#0C1E21', pb: '39px' }}>
                            {day.charAt(0).toUpperCase() + day.slice(1)}
                          </Typography>
                        ))}
                      </Box>
                    </Box>
                    <Box sx={{ mt: 4 }}>
                      <Box>
                        {allValues?.map((values) => (
                          <Box sx={{ py: 1 }}>
                            {values?.map((value) => (
                              <Typography sx={{ fontWeight: 'medium', fontFamily: 'Poppins', fontSize: '14px', color: '#0C1E21' }}>
                                {convertTo12HourFormat(value.guest_stime)} - {convertTo12HourFormat(value.guest_end_time)}
                              </Typography>
                            ))}
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={5}>
              <Box sx={{}}>
                <Box>
                  <AddRestaurant
                    tablelists={tablelists}
                    servicelists={servicelists}
                    restuinfo={restuinfo}
                    allValues={allValues}
                    allDays={allDays}
                    timelists={timelists}
                  ></AddRestaurant>
                </Box>
                <Box sx={{ mt: 4 }}>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <img src={dining} alt="dining" style={{ width: '30px', height: '30px' }} />
                    </Box>

                    <Typography
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontWeight: 'bold',
                        fontFamily: 'Poppins',
                        fontSize: '18px',
                        color: '#0C1E21'
                      }}
                    >
                      Dining Style
                    </Typography>
                  </Box>
                  {servicelists?.map((serList) => (
                    <Box key={serList.srvid}>
                      <Typography sx={{ fontWeight: 'medium', fontFamily: 'Poppins', fontSize: '13px', color: '#0C1E21' }}>
                        <ListItem sx={{ p: 0, ml: 5 }}>{serList?.srvtyp.charAt(0).toUpperCase() + serList?.srvtyp.slice(1)}</ListItem>
                      </Typography>
                    </Box>
                  ))}
                </Box>
                <Box sx={{ mt: 3 }}>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <img src={cuisine} alt="cuisine" style={{ width: '25px', height: '25px' }} />
                    </Box>
                    <Typography sx={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '18px', color: '#0C1E21' }}>Cuisine</Typography>
                  </Box>
                  <Box>
                    <Typography sx={{ p: 0, ml: 5, fontWeight: 'medium', fontFamily: 'Poppins', fontSize: '13px', color: '#0C1E21' }}>
                      {restuinfo?.rstbr_typ?.charAt(0).toUpperCase() + restuinfo?.rstbr_typ?.slice(1)}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ mt: 3, background: '#f1f1f1', width: '400px', py: 2, px: 1 }}>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Box>
                      <img src={pin} alt="pin" style={{ width: '25px', height: '25px' }} />
                    </Box>
                    <Typography sx={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '18px', color: '#0C1E21' }}>Address</Typography>
                  </Box>
                  <Box sx={{}}>
                    <Typography sx={{ p: 0, ml: 4, fontWeight: 'medium', fontFamily: 'Poppins', fontSize: '13px', color: '#0C1E21' }}>
                      {restuinfo?.rstbr_address}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </MainCard>
    </Box>
  );
}
